<template>
	<v-dialog
		:value="value" @input="$emit('input')"
		max-width="574">
<!--		<button class="close_modal" @click="dialog = false"></button>-->
		<div class="style_dialog error_dialog">
			<h2 class="header">
				Oops!
			</h2>
			<div class="cnt">
				Something went wrong
			</div>
			<div class="wrap_actions center">
				<button type="button" class="tetriatary_btn" @click="dialog = false" v-ripple>Cancel</button>
				<a href="mailto:info.psytrance.network@gmail.com?subject=Found a bug!" @click="dialog = false">Report</a>
			</div>
		</div>
	</v-dialog>
</template>

<script>
export default {
	name: "error",
	props: ['value'],
	data() {
		return {}
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {

	}
}
</script>

<style lang="scss">

</style>
